<template>
  <dash-page>
    <h1>{{ entity.name }}</h1>

    <div class="overflow-auto" >
      <v-sheet  class="pa-6 wsRoundedHalf mt-5"
                v-for="(question,i) in entity.questions"
                :key="i + '_question'"
                :ref="'question_' + i"
      >
        <!-- Question Header-->
        <div class="d-flex justify-space-between mb-3">
          <div class="d-flex align-center">
            <h4>{{ $t('Question')}} {{ i+1 }}</h4>
          </div>
          <div class="d-flex">

            <v-chip outlined :color="wsDARKER" small class="mr-2" v-bind="attrs" v-on="on">
              <v-icon x-small>mdi-cog</v-icon>
              {{ $t('Points') }} : {{ question.points }}
            </v-chip>

          </div>
        </div>
        <!-- Question Content-->
        <v-sheet class="px-3 py-3" v-html="question.content" />
        <!-- Question Answers -->
        <div v-if="question.type !== 'true_false' " class="mt-3" >
          <h5>{{ $t('Answers:')}}</h5>
          <div v-for="(answer,j) in question.answers" :key="i + j + '_answer'">
            <table width="100%">
              <tr>
                <td width="10px" valign="top" align="top">

                  <v-btn v-if="question.type === 'default' " @click="answer.correct = !answer.correct ; saveAnswer(answer)"
                         :color="answer.correct ?  'green lighten-2' : 'grey' "
                         small icon
                         class="mt-1">
                    <v-icon small>{{ answer.correct ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                  </v-btn>
                  <h5 class="mt-3 font-weight-light" v-if="question.type === 'order'">{{ j+1 }}</h5>

                </td>
                <td>
                  <div :style="`background: ${wsLIGHTCARD}`"
                       class="px-3 py-1 mt-2"
                       v-ripple style="cursor: pointer"
                       v-html="answer.content" />
                </td>
              </tr>
            </table>
          </div>

        </div>
        <!-- True/False Buttons-->
        <div v-if="question.type === 'true_false' " class="d-flex" >

          <div style="width: 50%">
            <v-btn @click="question.correct = true; "
                   :color="wsSUCCESS"
                   :text="!question.correct"
                   :dark="question.correct"
                   class="noCaps mt-3 mx-1"
                   block
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('Yes')}}
            </v-btn>
          </div>
          <div style="width: 50%">
            <v-btn @click="question.correct = false; "
                   :color="wsWARNING"
                   text
                   class="noCaps mt-3 mx-1"
                   block >
              <v-icon>mdi-minus</v-icon>
              {{ $t('No')}}
            </v-btn>
          </div>
        </div>

      </v-sheet>
    </div>

    <template #side>
      <div class="d-flex justify-end">
        <v-btn :loading="loading" @click="refreshTest" class="noCaps" outlined small> {{ $t('RefreshTest')}}</v-btn>
      </div>

      <!-- Questions Navigation-->
      <h5 class="mt-5">{{ $t('Navigation') }}:</h5>
      <div class="pr-6">
        <v-row  class="mt-1">

          <v-col v-for="(_,i) in entity.questions" :key="i + 'qNavigation'"
                 class="py-0 pb-1"
                 cols="2">
            <v-btn  @click="scrollToQuestion(i)"  min-width="42" width="42" class="noCaps" style="" outlined small  > {{ i+1 }}</v-btn>
          </v-col>

        </v-row>
      </div>

    </template>

  </dash-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DashTestPreview",
  props : ['uuid'],
  data() {
    return {
      loading : false,
      entity : {
        name : ''
      } ,
    }
  },
  methods : {
    ...mapActions('tests', [
      'GET_TEST_PREVIEW',
    ]),

    refreshTest() {
      this.initPage()
    },

    //technical
    scrollToQuestion(index) {
      this.$vuetify.goTo(this.$refs['question_' + index][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },

    initPage() {
      if ( this.uuid ) {
        this.loading = true;
        this.GET_TEST_PREVIEW(this.uuid).then((out)=>{
          if ( out.data ) {
            this.entity = out.data
          } else {
            this.notify(this.$t('NetworkError'))
          }
          this.loading = false
        }).catch(()=>{
          this.notify(this.$t('NetworkError'))
          this.loading = false })
      }
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>